import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#000',
				secondary: '#034E4A',
				accent: '#000',
				error: '#b71c1c',
				background: '#034E4A',
				nocolour: 'rgba(0,0,0,0)'
			},
		},
	},
	icons: {
		iconfont: 'md',
	}
});
// import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
// import {Ripple} from 'vuetify/lib/directives';
//
// Vue.use(Vuetify, {
// 	iconfont: 'md',
// 	theme: {
// 		primary: '#90c44f',
// 		secondary: '#408cfa',
// 		accent: '#055fe3',
// 		error: '#FF5A3D',
// 		info: '#2196F3',
// 		inactive: 'CCCCCC',
// 		success: '#4CAF50',
// 		warning: '#FFC107',
// 		white: '#FFFFFF'
// 	},
// 	directives: {
// 		Ripple
// 	}
// });
