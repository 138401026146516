<template>
  <v-app>
    <navigation-bar isFixed isFlat color="black"></navigation-bar>
    <router-view style="margin-bottom: 250px;"></router-view>
    <v-footer padless absolute>
      <v-col
          class="align-center"
      >
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 center"
            contain
            src="@/assets/footer-logo.png"
            transition="scale-transition"
            width="100"
        />
      </v-col>
      <v-col
          class="text-center"
          cols="12"
      >
        <strong>© Reserviläisurheiluliitto - {{ new Date().getFullYear() }}</strong>
        <p>Döbelninkatu 2<br/>00260 Helsinki<br/>Finland</p>
        <a href="mailto:info@resulfourdaymarch.fi">info@resulfourdaymarch.fi</a>
        <div class="icons">
          <a href="https://www.facebook.com/events/207100198349650" target="_blank">
            <v-btn fab depressed>
              <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/>
            </v-btn>
          </a>
          <a href="https://instagram.com/resulfourdaymarch" target="_blank">
          <v-btn fab depressed>
            <font-awesome-icon :icon="{ prefix: 'fab', iconName:'instagram' }" />
          </v-btn>
          </a>
      </div>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>
import NavigationBar from '@/components/NavigationBar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faFacebook)
library.add(faInstagram)

export default {
  name: 'App',

  components: {
    // RulesDialog,
    NavigationBar,
    FontAwesomeIcon
  },
  data: () => ({
    // isLoggedIn: false,
    // role: null
    //
    showScheduleForm: false,
    lang: 'fi',
    langs: [
      {lang: 'English', lngKey: 'en'},
      {lang: 'Suomi', lngKey: 'fi'},
    ],
  }),
  methods: {
    logout() {
      this.$store.dispatch('userSignOut').then((response) => {
        this.isLoggedIn = this.$store.getters.isAuthenticated
      })
    }
  },
  mounted() {
    this.role = this.$store.getters.roles;
    this.isLoggedIn = this.$store.getters.isAuthenticated;
  },
  beforeCreate() {
    this.isLoggedIn = this.$store.getters.isAuthenticated
    this.$store.dispatch('checkLogin').then((response) => {
      this.role = this.$store.getters.roles;
      console.log(this.role);
      this.isLoggedIn = this.$store.getters.isAuthenticated;
    });
  },
  watch: {
    lang: function (val) {
      this.$i18n.locale = this.langs[val].lngKey
    }
  }
};
</script>
<style>
.center {
  display: block;
  margin-left: calc(50% - 50px);
}
.uploadButtonStyle .v-btn__content {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  display: inline;
  padding-left: 5px;
}
.top-bar {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>