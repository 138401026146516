// import Vue from 'vue'
// import Vuex from 'vuex'
//
// Vue.use(Vuex)
//
// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })


import Vue from 'vue';
import Vuex from 'vuex';
import Axios from '@/api/apiconfig';
import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: null,
		roles: null,
		isAuthenticated: false,
	},

	mutations: {
		//Sets user session data
		setUserData(state, payload) {
			if (payload) {
				state.user = payload.user.id;
				state.isAuthenticated = payload.authenticated;
				//Validate user role exists and set it as an enum
				state.roles = payload.user.role[0];
			} else {
				//We land here when the user session is reset. Generally through the API logout.
				state.user = null;
				state.isAuthenticated = false;
				state.roles = null;
			}
		},

		//Just tracking user ID
		setUser(state, payload) {
			state.user = payload.data.user.id;
		},
	},
	actions: {
		//Sends credentials to API and logs user in
		userLogin({commit}, credentials) {
			return Axios.post('/auth/login', credentials, {withCredentials: true})
				.then(response => {
					commit('setUser', response.data);

					//window.localStorage.setItem("user_id", this.state.user)
					return response;
				}).catch((e) => {
					// console.log(e)
					//Error logging in
					// let errorMsg = e.data.data.message;
					return e;
				});
		},
		//Calls API to check login status
		checkLogin({commit}) {
			return Axios.get('/auth/check-loggedin', {withCredentials: true})
				.then(response => {
					response = response.data;
					console.log(response)
					if (response.data.authenticated) {
						commit('setUserData', response.data);
					} else {
						commit('setUserData', null);
					}
					return this.state.isAuthenticated;
				}).catch((e) => {
					//Error logging in
					window.getApp.$emit('ERROR_RESOURCES', e.name + ': ' + e.message);
					return false;
				});
		},
		//Calls API to log user out
		userSignOut({commit}) {
			return Axios.get('/auth/logout', {withCredentials: true})
				.then(response => {
					if (response) {
						commit('setUserData', null);
						return true;
					} else {
						return false;
					}
				}).catch((e) => {
					//Error logging in
					// window.getApp.$emit('ERROR_RESOURCES', e.name + ': ' + e.message);
					return false;
				});
		},
	},
	getters: {
		//Get user name
		//Get user id
		userID(state) {
			return state.user;
		},
		//Check if user is authed or not
		isAuthenticated(state) {
			return state.user !== null && state.user !== undefined && state.isAuthenticated;
		},
		//Use this to check if a user is admin
		isAdmin(state) {
			let isAdmin = false;

			//Loop through available roles and find if we have a match
			isAdmin = state.roles == 'admin';
			return isAdmin;
		},
		roles(state) {
			return state.roles;
		},
		//End of user role checks
		//Get first attempted path
	}
});