<template>
  <div>
    <v-app-bar
        app
        color="transparent"
        dark
        flat
        hide-on-scroll
        class="top-bar">
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/')"
          text
      >
        <v-icon dark>home</v-icon>
        <span class="mr-2">{{ $t('home') }}</span>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          href="https://www.facebook.com/events/207100198349650"
          target="_blank"
          text
          fab depressed
      >
       <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          href="https://www.instagram.com/resulfourdaymarch/"
          target="_blank"
          text
          fab depressed
      >
        <font-awesome-icon :icon="{ prefix: 'fab', iconName:'instagram' }" />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/info')"
          text
      >
        <v-icon dark>
          info
        </v-icon>
        <span class="mr-2">{{ $t('info') }}</span>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          href="https://resul-shop.fi/"
          target="_blank"
          text
      >
        <v-icon dark>
          shopping_cart
        </v-icon>
        <span class="mr-2">{{ $t('shop') }}</span>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/signup')"
          text
          v-if="!$store.getters.isAuthenticated"
      >
        <v-icon dark>
          app_registration
        </v-icon>
        <span class="mr-2">{{ $t('register') }}</span>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/competition_entry')"
          text
          v-if="$store.getters.isAuthenticated"
      >
        <span class="mr-2">{{ $t('enter-results') }}</span>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/competition_admin')"
          text
          v-if="$store.getters.isAuthenticated && $store.getters.roles=='admin'"
      >
        <span class="mr-2">{{ $t('admin') }}</span>
      </v-btn>
      <v-btn @click.stop="showScheduleForm=!showScheduleForm" text class="hidden-md-and-down">
        <v-icon dark>
          gavel
        </v-icon>
        <span class="mr-2">{{ $t('rules') }}</span>
        <RulesDialog v-model="showScheduleForm"/>
      </v-btn>
      <v-btn @click.stop="showFAQForm=!showFAQForm" text class="hidden-md-and-down">
        <v-icon dark>
          mdi-help-circle
        </v-icon>
        <span class="mr-2">{{ $t('faq') }}</span>
        <FAQDialog v-model="showFAQForm"/>
      </v-btn>
      <v-btn
          @click.stop="logout()"
          text
          class="hidden-md-and-down"
          v-if="$store.getters.isAuthenticated"
      >
        <v-icon dark>
          logout
        </v-icon>
        <span class="mr-2">{{$t('logout')}}</span>
      </v-btn>
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/login')"
          text
          v-if="!$store.getters.isAuthenticated"
      >
        <v-icon dark>
          login
        </v-icon>
        <span class="mr-2">{{$t('login')}}</span>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
              text          
              v-on="on"
          >
          <country-flag :country="langs[lang].lngFlag" size='small'/>
            {{$i18n.locale}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
              v-model="lang">
          <v-list-item
              v-for="(item, index) in langs"
              :key="index"
          >
            <v-list-item-title>
              {{ item.lang }}
              </v-list-item-title>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn
          class="hidden-md-and-down"
          @click.stop="$router.push('/edit_user')"
          text
          v-if="$store.getters.isAuthenticated"
      >
        <v-icon dark>
          account_circle
        </v-icon>
        <span class="mr-2">{{$t('account-setting')}}</span>
      </v-btn>
      <v-btn
          dark
          icon
          large
          @click.stop="drawer = !drawer"
          class="hidden-lg-and-up"
      >
        <v-icon>menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        app
        temporary
    >
      <v-toolbar dark flat color="accent">
        <router-link to="/" style="height:100%;">
          <div class="d-flex align-center">
            <v-btn
                @click.stop="$router.push('/')"
                text
            >
              <v-icon dark>home</v-icon>
              <span class="mr-2">{{ $t('home') }}</span>
            </v-btn>
          </div>
        </router-link>
      </v-toolbar>
      <v-list dense>
        <!--
          <v-list-tile @click.stop="$router.push('/')" :class="{'list-item-active': $route.path == '/'}" ripple>
            <v-list-tile-content>
              Home
            </v-list-tile-content>
          </v-list-tile>
        -->
        <template>
          <v-list-item-group
              color="black"
          >
            <v-list-item-title v-if="$store.getters.isAuthenticated" @click.stop="$router.push('/competition_entry')" ripple>
              <v-list-item-icon dark>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('enter-results') }}</span>
            </v-list-item-title>
            <v-list-item-title @click.stop="$router.push('/info')" ripple>
              <v-list-item-icon dark>
                <v-icon>
                  info
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('info') }}</span>
            </v-list-item-title>

            <v-list-item-title @click.stop="openShop()" ripple>
              <v-list-item-icon dark>
                <v-icon>
                  shopping_cart
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('shop') }}</span>
            </v-list-item-title>

            <v-list-item-title v-if="!$store.getters.isAuthenticated" @click.stop="$router.push('/signup')" ripple>
              <v-list-item-icon dark>
                <v-icon>
                  app_registration
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('register') }}</span>
            </v-list-item-title>
            <v-list-item-title @click.stop="showScheduleForm=!showScheduleForm;drawer=!drawer" ripple>
              <v-list-item-icon dark>
                <v-icon>
                  gavel
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('rules') }}</span>
            </v-list-item-title>
            <v-list-item-title @click.stop="showFAQForm=!showFAQForm;drawer=!drawer" ripple>
              <v-list-item-icon dark>
                <v-icon>
                  mdi-help-circle
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('faq') }}</span>
            </v-list-item-title>
            <v-list-item-title @click.stop="$router.push('/login')" ripple v-if="!$store.getters.isAuthenticated">
              <v-list-item-icon dark>
                <v-icon>
                  login
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t("login") }}</span>
            </v-list-item-title>

            <v-list-item-title @click.stop="logout()" ripple v-if="$store.getters.isAuthenticated">
              <v-list-item-icon dark>
                <v-icon>
                  logout
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('logout') }}</span>
            </v-list-item-title>

            <v-list-item-title @click.stop="$router.push('/edit_user')" ripple v-if="$store.getters.isAuthenticated">
              <v-list-item-icon dark>
                <v-icon>
                  account_circle
                </v-icon>
              </v-list-item-icon>
              <span class="mr-2 upper">{{ $t('account-setting') }}</span>
            </v-list-item-title>
          </v-list-item-group>
          <RulesDialog v-model="showScheduleForm"/>
          <FAQDialog v-model="showFAQForm"/>

        </template>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>
import RulesDialog from '@/components/RulesDialog'
import CountryFlag from 'vue-country-flag'
import FAQDialog from '@/components/FAQDialog'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faFacebook)
library.add(faInstagram)




export default {
  name: "NavigationBar",
  components: {
    RulesDialog,
    CountryFlag,
    FAQDialog,
    FontAwesomeIcon
  },
  props: 
    FAQDialog['isFixed', 'isAbsolute', 'isFlat', 'isScroll', 'color'],
  data() {
    return {
      links: [],
      drawer: false,
      showScheduleForm: false,
      showFAQForm: false,
      lang: 1,
      langs: [
        {lang: 'English', lngKey: 'en', lngFlag: 'gb'},
        {lang: 'Suomi', lngKey: 'fi', lngFlag: 'fi'},
      ],
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('userSignOut').then((response) => {
        this.isLoggedIn = this.$store.getters.isAuthenticated
      })
    },
    setFlag() {
      if (this.lang === "fi") {
        this.flagName = "fi"
      }
      else if (this.lang === "en") {
        this.flagName = "gb"
      }
    },
    openShop() {
      window.open('https://resul.sporttinurkka.fi/', '_blank').focus();
    }
  },
  watch: {
    lang: function (val) {
      this.$i18n.locale = this.langs[val].lngKey
    }
  }
}
</script>

<style scoped>
.upper {
  text-transform: capitalize;
}
.blue-background {
  background-color: #062F6E;
}
/* .padders {
  padding-bottom: 60px;
  } */
</style>